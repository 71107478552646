'use client'
export const isValidAdImpressionProduct = inputEvent => {
  const { products, slug } = inputEvent
  const isContainAd =
    Array.isArray(products) && products.some(item => item.adId)
  const isValidPage = ['/', '/search', '/categoryDetails'].includes(slug)
  return isContainAd && isValidPage
}

export const sendProductImpressions = function (payload) {
  window?._osAdImpression?.({
    uclids: payload.uclids,
    cli_ubid: payload.cli_ubid,
  })
}
