import {
  GTM_ENV_VAR as GEV,
  getNCheckFn as getNCheck,
  SPLIT_EVENT,
} from './eventTrackerFunction'
import {
  trackCitrusBannerImpressions,
  trackCitrusAdProduct,
} from './trackCitrus'
import {
  impressionTypes,
  getImpressionData,
  clearTrackedData,
  checkpoints,
} from './impressionsTracker'
import {
  isValidAdImpressionProduct,
  sendProductImpressions,
} from './osmos/impression/productImpression'
import { formatCtaLocation, getPageTypeByUrl } from './amplitude'
import { getCtaLocationMeta } from './utils'

const trackPromoImpressions = ({
  track,
  trackSplit,
  dbpStoreIdToTrack,
  storeIdToTrack,
  banners,
  currentType,
  params,
}) => {
  if (Array.isArray(banners) && banners.length && typeof track === 'function') {
    let event = {
      [GEV.EVENT]: 'promoImpression',
      [GEV.ECOMMERCE]: {
        promoView: {
          promotions: banners,
        },
      },
      [GEV.LIST_CAT]: storeIdToTrack,
      [GEV.DBP_STORE_ID]: dbpStoreIdToTrack,
    }
    // Added below params for GA4 migration requirement
    if (params?.isGA4BigBanners) {
      event[GEV.SNIPPET_VERSION] = 'ga4'
      event[GEV.EVENT_CAT] = 'ecommerce-engagement'
      event[GEV.EVENT_ACTION] = 'promotionimpressions'
      event[GEV.SCREEN_NAME_GA4] = params?.screenName
    }
    if (params?.asset_type) {
      event[GEV.ASSET_TYPE] = params.asset_type
    }
    if (params?.page_type) {
      const pageType = params.page_type
      const ga4lowerCaseType = ['Home', 'Category']
      event[GEV.PAGE_TYPE] =
        pageType &&
        typeof pageType === 'string' &&
        ga4lowerCaseType.includes(pageType)
          ? pageType.toLowerCase()
          : pageType
      event[GEV.SCREEN_NAME] = params.page_type
    }
    if (banners[0].isGA4) {
      event[GEV.ECOMMERCE] = {
        ...(banners[0] || {}),
      }
      delete event[GEV.ECOMMERCE].isGA4
      event[GEV.EVENT] = 'promotion_viewed'
    }
    if (params?.trackInfo?.isGA4Promo) {
      delete event[GEV.ECOMMERCE]
      event = { ecommerce: { items: banners, ...params?.trackInfo }, ...event }
      event[GEV.EVENT] = 'view_promotion'
      delete event[GEV.ECOMMERCE].isGA4Promo
    }
    // to remove screen_name from payload for GA4 requirement
    if (params?.isGA4BigBanners) {
      delete event[GEV.SCREEN_NAME]
    }
    track(event)
    if (['abandon', 'onUpdate'].includes(params?.eventType)) {
      trackAllPendingImpressions({
        track,
        storeIdToTrack,
        searchTermToTrack: '',
        trackSplit,
      })
    }
  } else if (currentType) {
    const bannerImpressionData = getImpressionData(currentType)
    const splitBanners = getImpressionData(`SPLIT_${currentType}`)
    if (splitBanners.length) {
      trackSplit(splitBanners)
      clearTrackedData(`SPLIT_${currentType}`)
    }
    if (bannerImpressionData.length) {
      trackPromoImpressions({
        track,
        trackSplit,
        storeIdToTrack,
        dbpStoreIdToTrack,
        banners: bannerImpressionData,
        params,
      })
      clearTrackedData(currentType)
    }
  }
}

const trackProductsInBatches = ({
  trackFn,
  storeIdToTrack,
  dbpStoreIdToTrack,
  searchTermToTrack,
  products,
  eventType = '',
  eventProps = {},
}) => {
  if (typeof trackFn === 'function') {
    const commonEventProps = {
      [GEV.EVENT]: 'productImpression',
      [GEV.EVENT_ACTION]: 'productimpressions',
      [GEV.EVENT_CAT]: 'ecommerce-engagement',
      [GEV.SNIPPET_VERSION]: 'ga4',
      [GEV.ASSET_TYPE]: 'fpon',
      [GEV.SCREEN_NAME_GA4]: formatCtaLocation(
        {
          pageName: getPageTypeByUrl(),
          pageMeta: getCtaLocationMeta() || [],
        },
        'grocery-online'
      ),
    }
    if (products.length > checkpoints.PAYLOAD_BREAK_LIMIT) {
      if (eventType === SPLIT_EVENT) {
        trackFn(products.slice(0, checkpoints.PAYLOAD_BREAK_LIMIT))
        trackFn(
          products.slice(checkpoints.PAYLOAD_BREAK_LIMIT, products.length)
        )
      } else {
        trackFn({
          ...commonEventProps,
          [GEV.ALGOLIA_EXP]: eventProps?.algolia_exp,
          [GEV.ECOMMERCE]: {
            currencyCode: 'SGD',
            [GEV.LIST_CAT]: storeIdToTrack,
            [GEV.DBP_STORE_ID]: dbpStoreIdToTrack,
            impressions: products.slice(0, checkpoints.PAYLOAD_BREAK_LIMIT),
          },
          [GEV.LIST_CAT]: storeIdToTrack,
          [GEV.DBP_STORE_ID]: dbpStoreIdToTrack,
          [GEV.SEARCH_TERM]: searchTermToTrack,
          [GEV.KEYWORD_SELECTED]: searchTermToTrack,
          ...eventProps,
        })

        trackFn({
          ...commonEventProps,
          [GEV.ALGOLIA_EXP]: eventProps?.algolia_exp,
          [GEV.ECOMMERCE]: {
            currencyCode: 'SGD',
            [GEV.LIST_CAT]: storeIdToTrack,
            [GEV.DBP_STORE_ID]: dbpStoreIdToTrack,
            impressions: products.slice(
              checkpoints.PAYLOAD_BREAK_LIMIT,
              products.length
            ),
          },
          [GEV.LIST_CAT]: storeIdToTrack,
          [GEV.DBP_STORE_ID]: dbpStoreIdToTrack,
          [GEV.SEARCH_TERM]: searchTermToTrack,
          [GEV.KEYWORD_SELECTED]: searchTermToTrack,
          ...eventProps,
        })
      }
    } else {
      if (eventType === SPLIT_EVENT) {
        trackFn(products)
      } else {
        trackFn({
          ...commonEventProps,
          [GEV.ALGOLIA_EXP]: eventProps?.algolia_exp,
          [GEV.ECOMMERCE]: {
            currencyCode: 'SGD',
            [GEV.LIST_CAT]: storeIdToTrack,
            [GEV.DBP_STORE_ID]: dbpStoreIdToTrack,
            impressions: products,
          },
          [GEV.LIST_CAT]: storeIdToTrack,
          [GEV.DBP_STORE_ID]: dbpStoreIdToTrack,
          [GEV.SEARCH_TERM]: searchTermToTrack,
          [GEV.KEYWORD_SELECTED]: searchTermToTrack,
          ...eventProps,
        })
      }
    }
  }
}

const trackAllPendingImpressions = ({
  track,
  storeIdToTrack,
  dbpStoreIdToTrack,
  searchTermToTrack,
  remoteConfig,
  trackSplit,
  eventProps,
}) => {
  const {
    PRODUCT_IMPRESSION,
    HOMEPAGE_PRODUCT_IMPRESSION,
    BANNER_IMPRESSION,
    OTHER_BANNER_IMPRESSION,
    SPLIT_PRODUCT_IMPRESSION,
    SPLIT_HOMEPAGE_PRODUCT_IMPRESSION,
    SPLIT_BANNER_IMPRESSION,
    SPLIT_OTHER_BANNER_IMPRESSION,
  } = impressionTypes

  const pendingImpressions = {
    productImpression: [],
    promoImpression: [],
    splitProductImpression: [],
    splitBannerImpression: [],
  }
  for (const type in impressionTypes) {
    const data = getImpressionData(type) || []
    if ([PRODUCT_IMPRESSION, HOMEPAGE_PRODUCT_IMPRESSION].includes(type)) {
      pendingImpressions['productImpression'].push(...data)
    } else if ([BANNER_IMPRESSION, OTHER_BANNER_IMPRESSION].includes(type)) {
      pendingImpressions['promoImpression'].push(...data)
    } else if (
      [SPLIT_PRODUCT_IMPRESSION, SPLIT_HOMEPAGE_PRODUCT_IMPRESSION].includes(
        type
      )
    ) {
      pendingImpressions['splitProductImpression'].push(...data)
    } else if (
      [SPLIT_BANNER_IMPRESSION, SPLIT_OTHER_BANNER_IMPRESSION].includes(type)
    ) {
      pendingImpressions['splitBannerImpression'].push(...data)
    }
    clearTrackedData(type)
  }

  for (const imprType in pendingImpressions) {
    const data = pendingImpressions[imprType]
    if (data.length) {
      if (imprType === 'productImpression') {
        trackProductsInBatches({
          trackFn: track,
          storeIdToTrack,
          dbpStoreIdToTrack,
          searchTermToTrack,
          products: data,
          eventProps,
        })
      } else if (imprType === 'promoImpression') {
        trackPromoImpressions({
          track,
          storeIdToTrack,
          dbpStoreIdToTrack,
          banners: data,
        })
      } else if (imprType === 'splitProductImpression') {
        trackProductsInBatches({
          trackFn: trackSplit,
          storeIdToTrack: '',
          dbpStoreIdToTrack: '',
          searchTermToTrack: '',
          products: data,
          eventType: SPLIT_EVENT,
          eventProps,
        })
      } else if (imprType === 'splitBannerImpression') {
        trackSplit(data)
      }
    }
  }
  trackCitrusAdProduct(remoteConfig)
  trackCitrusBannerImpressions()
}

const trackProductImpressions = ({
  params,
  currentType,
  storeIdToTrack,
  dbpStoreIdToTrack,
  searchTermToTrack,
  track,
  trackSplit,
  products,
  remoteConfig,
  eventProps,
  accountData,
  isEnabled = {},
  router = {},
}) => {
  let trackImpression = false
  if (
    [
      impressionTypes.PRODUCT_IMPRESSION,
      impressionTypes.HOMEPAGE_PRODUCT_IMPRESSION,
    ].includes(currentType)
  ) {
    if (
      params?.thresholdReached ||
      params?.forceTrack ||
      (params?.forceTrack &&
        ['abandon', 'onUpdate'].includes(params?.eventType)) ||
      (params?.eventType === 'paginate' &&
        products.length > checkpoints.LEFTOVER_LIMIT)
    ) {
      trackImpression = true
    }
  }

  if (trackImpression) {
    trackCitrusAdProduct(remoteConfig)
    trackProductsInBatches({
      trackFn: track,
      storeIdToTrack,
      dbpStoreIdToTrack,
      searchTermToTrack,
      products,
      eventProps,
    })
    if (isEnabled?.isOsmosFlagEnabled) {
      const isValid = isValidAdImpressionProduct({
        products,
        slug: router?.pathname,
      })
      if (isValid) {
        sendProductImpressions({
          uclids: products.map(product => product.adId).filter(adId => adId),
          cli_ubid: getNCheck(accountData, 'uid', '0'),
        })
      }
    }

    clearTrackedData(currentType)

    const splitProducts = getImpressionData(`SPLIT_${currentType}`)
    trackProductsInBatches({
      trackFn: trackSplit,
      storeIdToTrack: '',
      dbpStoreIdToTrack: '',
      searchTermToTrack: '',
      products: splitProducts,
      eventType: SPLIT_EVENT,
      eventProps,
    })
    clearTrackedData(`SPLIT_${currentType}`)

    if (['abandon', 'onUpdate'].includes(params?.eventType)) {
      trackAllPendingImpressions({
        track,
        storeIdToTrack,
        dbpStoreIdToTrack,
        searchTermToTrack,
        trackSplit,
        eventProps,
      })
    }
  }
}

export { trackPromoImpressions, trackProductImpressions }
